import Layer from '@mozaic-ds/freemarker/js/modules/_layer.js';
import HttpModule from '../tools/http.module';
import { ADD_TO_CART, HIDDEN_CSS_CLASS, HTTP_HEADERS, LAYER } from './add-to-cart.constants';
import { dispatchAtcTracking, enableDisabledAllATCButtons } from '../tools/utils.class';
import ModalBuilder from '../tools/modal-builder.class';
import CheckCartService from './check-cart.service';
export default class AddToCartLayerMode {
  constructor(resourceType) {
    this.check = false;
    this.layer = new Layer({
      classContent: LAYER.SELECTOR
    });
    this.addedProductContainer = this.layer.layer.querySelector(`.${LAYER.ADDED_CONTAINER_SELECTOR}`);
    this.responseAddedCartContainer = this.addedProductContainer.querySelector(`.${LAYER.RESPONSE_SELECTOR}`);
    this.loader = this.addedProductContainer.querySelector(`.${LAYER.LOADER_SELECTOR}`);
    this.modalBuilder = new ModalBuilder();
    this.checkCartService = new CheckCartService();
    this.isCollaborator = resourceType === 'collaborator';
  }
  updateCart(parameters) {
    var _a, _b;
    const lightMode = (_a = parameters.products[0]) === null || _a === void 0 ? void 0 : _a.lightMode;
    if (!lightMode) {
      this.layer.open();
      this.dispatchTrackingOpenedLayer();
      this.closeOtherOpenedLayers();
      this.responseAddedCartContainer.innerHTML = '';
      this.loader.classList.remove(HIDDEN_CSS_CLASS);
    }
    this.atcButtonLocation = (_b = parameters.products[0]) === null || _b === void 0 ? void 0 : _b.atcButtonLocation;
    this.dispatchCartUpdateEvent(ADD_TO_CART.EVENT_ADDED);
    let collaboratorRoute = '';
    if (this.isCollaborator) {
      collaboratorRoute = 'collaborator/';
    }
    let route = `/services/${collaboratorRoute}addToCart`;
    if (lightMode) {
      route += '/light';
    }
    const headersAddToCart = this.getHeadersForAddToCart();
    const httpModule = new HttpModule(route);
    const params = {
      body: JSON.stringify(parameters.products),
      headers: headersAddToCart,
      success: response => this.successCheckCart(response, parameters.products[0], lightMode),
      error: () => this.errorCheckCart(parameters.products[0], lightMode)
    };
    httpModule.post(params);
  }
  successCheckCart(response, product, lightMode) {
    let inError = false;
    let tracking = null;
    if (response.charAt(0) === '{') {
      const responseObject = JSON.parse(response);
      if (responseObject) {
        if (responseObject.code) {
          inError = true;
          this.dispatchCartUpdateEvent(ADD_TO_CART.EVENT_ERROR, {
            detail: {
              product
            }
          });
          if (!lightMode) {
            setTimeout(() => {
              this.showErrorModal(responseObject);
              this.layer.close();
            }, 500);
          }
        } else if (responseObject.tracking) {
          tracking = JSON.parse(responseObject.tracking);
        }
      }
    }
    if (!inError) {
      this.loader.classList.add(HIDDEN_CSS_CLASS);
      if (!lightMode) {
        this.insertNewHtmlContent(this.responseAddedCartContainer, response);
        const trackingElement = document.querySelector('script.js-atcDataTracking');
        if (trackingElement) {
          tracking = JSON.parse(trackingElement.innerHTML);
        }
        const CloseButtonElement = document.querySelector('.js-custom-close-layer');
        CloseButtonElement.addEventListener('click', () => {
          this.layer.close();
        });
      }
      enableDisabledAllATCButtons(false);
      this.dispatchCartUpdateEvent(ADD_TO_CART.EVENT_UPDATED);
      this.dispatchCartUpdateEvent(ADD_TO_CART.EVENT_SUCCESS, {
        detail: {
          product
        }
      });
      if (tracking) {
        dispatchAtcTracking({
          target: window,
          eventType: 'cdl_add_to_cart',
          trackingData: Object.assign(Object.assign({}, tracking), this.atcButtonLocation ? {
            button_location: `${window.tc_vars['cdl_page_subtype']} - ${this.atcButtonLocation}`
          } : {})
        });
      }
      lm.lazyload.observe();
    }
  }
  errorCheckCart(product, lightMode) {
    if (!lightMode) {
      const modalDetailsConfig = {
        content: this.getErrorCode('default-error')
      };
      this.modalBuilder.display(modalDetailsConfig);
      this.layer.close();
    }
    enableDisabledAllATCButtons(false);
    this.dispatchCartUpdateEvent(ADD_TO_CART.EVENT_ERROR, {
      detail: {
        product
      }
    });
  }
  insertNewHtmlContent(prmDomElement, prmHtml) {
    var _a;
    const dom = new DOMParser().parseFromString(`<template>${prmHtml}</template>`, 'text/html').head;
    prmDomElement.appendChild((_a = dom.firstElementChild) === null || _a === void 0 ? void 0 : _a.content);
  }
  dispatchTrackingOpenedLayer() {
    const trackingData = {
      event_name: 'popin.display',
      event_data: {
        feature_name: 'add-to-cart',
        popin_name: 'add to cart layer'
      }
    };
    dispatchAtcTracking({
      target: window,
      eventType: 'cdl_event',
      trackingData
    });
  }
  getHeadersForAddToCart() {
    let headers = HTTP_HEADERS;
    if (this.isCollaborator) {
      const customerCartValue = this.getCustomerIdFromCookie();
      const customerCart = customerCartValue ? customerCartValue : 'no customer';
      headers = Object.assign(Object.assign({}, headers), {
        'X-Customer-Cart': customerCart
      });
    }
    return headers;
  }
  showErrorModal(errorObject) {
    const modalDetails = {
      content: this.getErrorCode(errorObject.code)
    };
    enableDisabledAllATCButtons(false);
    if (errorObject.code === 'missing-mandatory-customer') {
      modalDetails.action = this.checkCartService.reAuth;
    }
    this.modalBuilder.display(modalDetails);
    this.dispatchCartUpdateEvent(ADD_TO_CART.EVENT_UPDATED);
  }
  closeOtherOpenedLayers() {
    const othersOpenedLayers = document.querySelectorAll('div[id^=\'js-layer\']:not([aria-hidden=\'true\']):not([class*=\'js-atc-layer\'])');
    if (othersOpenedLayers.length > 0) {
      othersOpenedLayers.forEach(layer => {
        layer.setAttribute('aria-hidden', 'true');
        layer.setAttribute('tabindex', '-1');
        const dialogContainer = layer.querySelector('.mc-layer__dialog');
        if (dialogContainer !== null && dialogContainer !== undefined) {
          dialogContainer.classList.remove('is-open');
        }
      });
    }
  }
  getCustomerIdFromCookie() {
    var _a;
    const customerSalesCookie = lm.cookie.get(ADD_TO_CART.CUSTOMER_SALES_COOKIE_KEY);
    let customerBannerObject = {};
    try {
      customerBannerObject = JSON.parse(customerSalesCookie ? atob(customerSalesCookie) : '{}');
    } catch (error) {
      console.error('Error parsing customerSales cookie', error);
    }
    return (_a = customerBannerObject.id) !== null && _a !== void 0 ? _a : 'no customer';
  }
  dispatchCartUpdateEvent(eventName, eventData) {
    document.dispatchEvent(new CustomEvent(eventName, eventData));
  }
  getErrorCode(code) {
    var _a;
    let translation = '';
    const translations = (_a = window.cartcdp) === null || _a === void 0 ? void 0 : _a.translation;
    if (translations) {
      translation = translations[code];
      if (!translation) {
        translation = translations['default-error'];
      }
    }
    return translation;
  }
}